import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        siteUrl
        author
        siteDescription: description
        image
        siteTitle: title
        twitterUsername
      }
    }
  }
`

const SEO = ({ title, description }) => {

  const {site} = useStaticQuery(query);
  const {siteDescription, siteTitle, siteUrl, image, twitterUsername} = site.siteMetadata;

  return <Helmet htmlAttributes={{lang: "en"}} title={`${title} | ${siteTitle}`}>
    <meta name="description" content={description || siteDescription}></meta>
    <meta name="image" content={image}></meta>
    {/* twitter cards */}
    <meta name="twitter:card" content="summary_large_image" /> 
    <meta name="twitter:creator" content={twitterUsername} /> 
    <meta name="twitter:title" content={siteTitle} /> 
    <meta name="twitter:description" content={siteDescription} /> 
    <meta name="twitter:image" content={`${siteUrl}${image}`} /> 
  </Helmet>
}

export default SEO
