import { Link } from "gatsby"
import React from "react"
import SocialLinks from "../constants/social_links"

const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <SocialLinks styleClass="footer-links"></SocialLinks>
        <h4>
          Created with ❤️ using <Link to="https://www.gatsbyjs.com/">GatsbyJS</Link> and <Link to="https://strapi.io/">Strapi</Link>
        </h4>
      </div>
    </footer>
  )
}

export default Footer
